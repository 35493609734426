<template>
    <section>
        <div class="columns">
            <div class="column">404</div>
        </div>    
    </section>
</template>
<script>
export default {
    name: 'NotFound'
}
</script>